import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles({
  label: {
    fontWeight: "bold",
  },
});

export default function PhaseGroupList({ label, groupList }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography variant="subtitle1" className={classes.label}>
        {label}
      </Typography>
      <List>
        {groupList.map((group) => {
          return (
            <ListItem>
              <ListItemText>
                <Typography variant="body1">{group.name}</Typography>
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
    </React.Fragment>
  );
}
