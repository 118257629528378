// vendor imports (from npm)
import React from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Chip from "@material-ui/core/Chip";
import { CardHeader } from "@material-ui/core";
import Link from "@material-ui/core/Link";

// local imports (in this package)
import PhaseList from "../PhaseList";
import StateResourceLinkListItem from "../StateResourceLinkListItem";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

export default function AlbumCard({ id, state, selected }) {
  const classes = useStyles();

  const { phases } = state;
  const activePhases = phases.filter((x) => x.active);
  const inactivePhases = phases.filter((x) => !x.active);

  const hasUsefulLinks = state.phasesUrl || state.programUrl || state.schedulingUrl;

  return (
    <React.Fragment>
      <Grid item key={id} xs={12} sm={selected ? 12 : 6} md={selected ? 12 : 6}>
        <Card className={classes.card}>
          <CardHeader title={state.name}></CardHeader>
          <CardActions>
            <Button variant="contained" size="large" color="primary" href={`/states/${state.name}`}>
              View Plan Details
            </Button>
            <Button variant="contained" size="large" color="primary" href={state.phasesUrl}>
              Visit State Website
            </Button>
          </CardActions>
          <CardContent className={classes.cardContent}>
            {hasUsefulLinks && (
              <>
                <Typography variant="h5">Useful Links</Typography>
                <List aria-label="list of phases">
                  {state.schedulingUrl && (
                    <StateResourceLinkListItem label="Schedule Vaccination Appointments" url={state.schedulingUrl} />
                  )}
                  {state.programUrl && (
                    <StateResourceLinkListItem label="Vaccine Program Overview" url={state.programUrl} />
                  )}
                  {state.phasesUrl && <StateResourceLinkListItem label="Phases" url={state.phasesUrl} />}
                </List>
              </>
            )}
            <List aria-label="list of phases">
              <ListItem>
                <ListItemText>
                  <PhaseList label="Active Phases" phases={activePhases} chipLabel="Active" />
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <PhaseList label="Upcoming Phases" phases={inactivePhases} chipLabel="Upcoming" />
                </ListItemText>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Grid>
    </React.Fragment>
  );
}
