const data = {
  name: "Massachusetts",
  phasesUrl: "https://www.mass.gov/info-details/covid-19-vaccine-distribution-timeline-phase-overview",
  programUrl: "https://www.mass.gov/info-details/covid-19-vaccination-program",
  schedulingUrl: "https://www.mass.gov/CovidVaccineMap",
  phases: [
    {
      name: "Phase 1 ",
      dates: "December 2020 – February 2021",
      active: true,
      details: "Listed in order of Priority",
      currentGroups: [
        {
          name: "Clinical and non-clinical health care workers doing direct and COVID-facing care",
        },
        {
          name: "Long term care facilities, rest homes and assisted living facilities",
        },
        {
          name: "Emergency medical services, police, and fire",
        },
        {
          name: "Congregate care settings",
        },
        {
          name: "Home-based health care workers",
        },
        {
          name: "Health care workers doing non-COVID-facing care",
        },
        {
          name: "Individuals who do not come into contact with patients",
        },
      ],
    },
  ],
};

export default data;
