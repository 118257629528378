const data = {
  name: "Connecticut",
  shortInfo: "Phase 1a",
  phasesUrl: "https://portal.ct.gov/Coronavirus/COVID-19-Vaccination---Phases",
  phases: [
    {
      name: "Phase 1a",
      active: true,
      currentGroups: [
        {
          name: "Healthcare Personnel",
          description:
            "All paid and unpaid persons serving in healthcare settings who have the potential for direct or indirect exposure to patients of infectious materials. Healthcare personnel should contact their Employer Coordinator, who has been designated to ensure access to the vaccine. Vaccine administration for healthcare workers will be available at hospitals, outpatient clinics, and local health departments.",
        },
        {
          name: "Long-Term Care Facility Residents",
          description:
            "Adults who reside in facilities that provide a range of services, including medical and personal care, to persons who are unable to live independently. Residents of long-term care facilities should ask facility leadership about reviewing vaccine. All vaccine clinics in long-term care facilities will be administered by CVS and Walgreens. ",
        },
        {
          name: "Medical First Responders",
          description:
            "High risk of exposure to COVID-19 through their response to medical emergencies such as Emergency Medical Technicians, Police, and Fire.",
        },
      ],
    },
    {
      name: "Phase 1b",
      active: true,
      currentGroups: [
        {
          name: "Individuals 75 years of age and older",
          description: null,
          schedulingUrl: "https://portal.ct.gov/Coronavirus/COVID-19-Vaccination---75-and-older",
        },
      ],
      upcomingGroups: [
        {
          name: "Front line essential workers",
          description:
            "Frontline essential workers face work-related exposure to COVID-19 because work-related duties must be performed on-site, in proximity (<6 feet) to the public or to coworkers AND are in one of the following sectors:",
          detailedList: [
            "Healthcare personnel not included in Phase 1a",
            "First responders",
            "Agricultural workers, including farmworkers",
            "Food service and restaurants",
            "U.S. Postal Service workers",
            "Manufacturing workers",
            "Grocery store & pharmacy workers",
            "Public transit workers",
            "Food banks and meal delivery services for the elderly",
            "Education and child-care workers",
            "Solid waste and wastewater workers",
            "Inspectors working on site in the above locations",
            "Frontline public and social services",
          ],
        },
        {
          name: "Individuals between the ages of 65 and 74",
          description: null,
        },
        {
          name: "Individuals between the ages of 16 and 64 with comorbidities",
          description: null,
        },
        {
          name: "Individuals and staff in congregate settings",
          description:
            "Congregate settings include individuals and staff in halfway homes, inpatient mental health facilities, corrections facilities, homeless shelters, domestic violence shelters, substance use and residential treatment facilities along with others. More information about congregate settings will be available soon.  Vaccination programs for congregate settings will be coordinated by State and local health.",
        },
      ],
    },
    {
      name: "Phase 1c",
      active: false,
      description: "Updated information will be coming soon!",
    },
  ],
};

export default data;
