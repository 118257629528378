import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({}));

export default function StateResourceLinkListItem({ label, url }) {
  const classes = useStyles();

  return (
    <React.Fragment key={label}>
      <ListItem>
        <ListItemText>
          <Link href={url}>{label}</Link>
        </ListItemText>
      </ListItem>
    </React.Fragment>
  );
}
