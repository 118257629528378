import React from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import PhaseGroupList from "./PhaseGroupList/PhaseGroupList";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({}));

export default function PhaseList({ phases, chipLabel }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography gutterBottom variant="h5">
        Upcoming Phases
      </Typography>
      {phases.length === 0 && (
        <Typography gutterBottom variant="body1">
          No information at this time. Check back soon.
        </Typography>
      )}
      {phases.length > 0 &&
        phases.map((phase) => {
          return (
            <List>
              <ListItem divider>
                <ListItemText>
                  <Typography variant="h6">
                    {phase.name}
                    <Box component="span" m={2}>
                      <Chip label={chipLabel} />
                    </Box>
                  </Typography>
                  {phase.description && <Typography variant="body1">{phase.description}</Typography>}
                  {phase.currentGroups && (
                    <PhaseGroupList label="Currently Vaccinating:" groupList={phase.currentGroups} />
                  )}
                  {phase.upcomingGroups && (
                    <PhaseGroupList label="Upcoming groups (vaccinating soon):" groupList={phase.upcomingGroups} />
                  )}
                </ListItemText>
              </ListItem>
            </List>
          );
        })}
    </React.Fragment>
  );
}
