import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import CameraIcon from "@material-ui/icons/PhotoCamera";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import AlbumCard from "../AlbumCard/AlbumCard";
import States from "../../data/states";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";

const websiteName = "covidvaxplan.com";

function ShortDisclaimer() {
  return (
    <React.Fragment>
      <Container maxWidth="md">
        <Box>
          <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
            This website is made by volunteers. We make no guarantees about the accuracy of the provided information. We
            are doing our best to keep it up to date, but you should check with your state government website to be
            sure.
          </Typography>
          <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
            <Link href="#disclaimer">Click here to read our full disclaimer</Link>
          </Typography>
        </Box>
      </Container>
    </React.Fragment>
  );
}

function Disclaimer() {
  return (
    <React.Fragment>
      <Container id="disclaimer" maxWidth="md">
        <Typography variant="h5" align="center" color="textSecondary" component="h5">
          Disclaimer
        </Typography>
        <Typography variant="h6" align="center" color="textSecondary" component="h6">
          Introduction
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          The information provided by this website (“we,” “us” or “our”) on {websiteName} (the “Site”) is for general
          informational purposes only. All information on the Site [and our mobile application] is provided in good
          faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy,
          adequacy, validity, reliability, availability or completeness of any information on the Site.
        </Typography>
        <br />
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a
          result of the use of the site [or our mobile application] or reliance on any information provided on the site
          [and our mobile application]. Your use of the site [and our mobile application] and your reliance on any
          information on the site [and our mobile application] is solely at your own risk. This disclaimer template was
          created using Termly.
        </Typography>

        <br />

        <Typography variant="h6" align="center" color="textSecondary" component="h6">
          External Links Disclaimer for website
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          The Site [and our mobile application] may contain (or you may be sent through the Site [or our mobile
          application] links to other websites or content belonging to or originating from third parties or links to
          websites and features in banners or other advertising. Such external links are not investigated, monitored, or
          checked for accuracy, adequacy, validity, reliability, availability or completeness by us.
        </Typography>
        <br />

        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any
          information offered by third-party websites linked through the site or any website or feature linked in any
          banner or other advertising. We will not be a party to or in any way be responsible for monitoring any
          transaction between you and third-party providers of products or services.
        </Typography>

        <br />
        <Typography variant="h6" align="center" color="textSecondary" component="h6">
          Professional Disclaimer for website
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          The Site cannot and does not contain [medical/legal/fitness/health/other] advice. The
          [legal/medical/fitness/health/other] information is provided for general informational and educational
          purposes only and is not a substitute for professional advice.
        </Typography>
        <br />
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          Accordingly, before taking any actions based upon such information, we encourage you to consult with the
          appropriate professionals. We do not provide any kind of [medical/legal/fitness/health/other] advice. The use
          or reliance of any information contained on this site [or our mobile application] is solely at your own risk.
        </Typography>
      </Container>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

export default function Album() {
  const classes = useStyles();
  const [selectedState, setSelectedState] = React.useState(null);
  const [stateLookupValue, setStateLookupValue] = React.useState("");

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <CameraIcon className={classes.icon} />
          <Typography variant="h6" color="inherit" noWrap>
            COVID Vaccine Plans by State
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              COVID Vaccine Plans
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              Choose your state to find out when you are eligibile to receive the COVID vaccine and learn how you can
              get it.
            </Typography>
            <ShortDisclaimer />
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Autocomplete
                    value={selectedState}
                    onChange={(event, newValue) => {
                      setSelectedState(newValue);
                    }}
                    inputValue={stateLookupValue}
                    onInputChange={(event, newInputValue) => {
                      setStateLookupValue(newInputValue);
                    }}
                    id="state-lookup"
                    options={States}
                    getOptionLabel={(option) => option.name}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Choose your State" variant="outlined" />}
                  />
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {selectedState && (
            <Grid container spacing={4}>
              <AlbumCard id={selectedState.name} state={selectedState} selected />
            </Grid>
          )}
          {!selectedState && (
            <Grid container spacing={4}>
              {States.map((x) => (
                <AlbumCard id={x.name} state={x} />
              ))}
            </Grid>
          )}
        </Container>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          Made by volunteers.
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          We hope this helps. If so, please pay it forward in your own way.
        </Typography>

        <Box m={5}>
          <Disclaimer />
        </Box>
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}
